import classNames from "classnames";
import React, { useContext, useRef, useState } from "react";
import { useFocused, useSelected, useSlate } from "slate-react";
import { PopOver } from "../../../nav/PopOver";
import { EditorContext } from "../RichTextEditor";
import { updateImage } from "./commands";
import styles from "./ImageComponent.scss";
import { ImageControls } from "./ImageControls";

export const ImageComponent = ({ attributes, element, children }) => {
    const { onImageDelete } = useContext(EditorContext);
    const editor = useSlate();
    const imageRef = useRef(null);
    const frameRef = useRef(null);
    const selected = useSelected();
    const focused = useFocused();

    const handleUpdate = (props) => {
        updateImage(editor, {
            maxWidth: element.maxWidth,
            align: element.align,
            ...props
        });
    };

    const handleDelete = () => {
        if (onImageDelete && element.refId) {
            onImageDelete(element.refId);
        }
    };

    return (
        <div {...attributes} className={styles.base}>
            <div ref={frameRef}>
                {children}
                <PopOver
                    visible={selected && focused}
                    positions={["bottom", "top"]}
                    padding="small"
                    size="auto"
                    content={<ImageControls element={element} onUpdate={handleUpdate} onDelete={handleDelete} />}
                >
                    <div
                        className={classNames(styles.image, { [styles.alignCenter]: element.align === "center" })}
                        style={{
                            maxWidth: element.maxWidth && parseInt(element.maxWidth, 10) > 0 ? element.maxWidth : null
                        }}
                        contentEditable={false}
                    >
                        <img ref={imageRef} src={element.src} alt={element.alt} />
                        {selected && focused && (
                            <ImageTools
                                element={element}
                                imageRef={imageRef}
                                frameRef={frameRef}
                                onUpdate={handleUpdate}
                            />
                        )}
                    </div>
                </PopOver>
            </div>
        </div>
    );
};

const ImageTools = ({ element, imageRef, frameRef, onUpdate }) => {
    const [dragging, setDragging] = useState(false);
    const initProps = useRef({ xPos: 0, currentWidth: 0 });

    const handleScale = (e) => {
        const { xPos, currentWidth, maxWidth } = initProps.current;
        const pageX = e.touches?.length ? e.touches[0].pageX : e.pageX;
        if (!pageX) return;
        const movedX = Math.round(pageX - xPos) * (element.align === "center" ? 2 : 1);
        const newWidth = Math.max(100, Math.min(maxWidth, Math.round(currentWidth + movedX)));
        onUpdate({ maxWidth: `${newWidth}px` });
    };

    const startScale = (e) => {
        const isTouch = e.touches?.length;
        if (!isTouch) {
            e.preventDefault();
        }

        setDragging(true);
        initProps.current = {
            xPos: isTouch ? e.touches[0].pageX : e.pageX,
            currentWidth: imageRef?.current?.offsetWidth,
            maxWidth: frameRef?.current?.offsetWidth
        };
        document.body.addEventListener("touchmove", handleScale, { passive: false });
        document.body.addEventListener("touchend", stopScale, { passive: false });
        document.body.addEventListener("mousemove", handleScale);
        document.body.addEventListener("mouseup", stopScale);
    };

    const stopScale = () => {
        setDragging(false);
        document.body.removeEventListener("touchmove", handleScale);
        document.body.removeEventListener("touchend", stopScale);
        document.body.removeEventListener("mousemove", handleScale);
        document.body.removeEventListener("mouseup", stopScale);
    };

    return (
        <div className={styles.tools} contentEditable={false}>
            <div className={classNames(styles.label, { [styles.visible]: dragging })}>{element.maxWidth}</div>
            <button className={styles.scaleButton} onMouseDown={startScale} onTouchStart={startScale}>
                <span className={styles.line}></span>
            </button>
        </div>
    );
};
