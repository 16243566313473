import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import styles from "./ScrollableArea.scss";

export const ScrollableArea = ({
    children,
    onScrollX,
    onScrollY,
    suppressScrollX,
    suppressScrollY,
    handlers = ["click-rail", "drag-thumb", "keyboard", "wheel", "touch"],
    silent
}) => {
    const areaRef = useRef(null);
    const updateTimeout = useRef(null);

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("resize", handleResize, false);
        }
        return () => {
            if (typeof window !== "undefined") {
                window.removeEventListener("resize", handleResize, false);
            }
            clearTimeout(updateTimeout.current);
        };
    }, []);

    const handleResize = () => {
        clearTimeout(updateTimeout.current);
        updateTimeout.current = setTimeout(() => {
            areaRef.current.updateScroll();
        }, 300);
    };

    return (
        <PerfectScrollbar
            ref={areaRef}
            className={classNames(styles.base, {
                [styles.silent]: silent
            })}
            options={{
                suppressScrollX,
                suppressScrollY,
                handlers
            }}
            onScrollX={onScrollX}
            onScrollY={onScrollY}
        >
            {children}
        </PerfectScrollbar>
    );
};
