import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSlate } from "slate-react";
import { Button } from "../../../../button/Button";
import { ButtonGroup } from "../../../../button/ButtonGroup";
import Col from "../../../../grid/Col";
import Row from "../../../../grid/Row";
import { Menu, MenuItem } from "../../../../nav/Menu";
import { PopOver } from "../../../../nav/PopOver";
import { deleteColumn } from "../commands/deleteColumn";
import { deleteRow } from "../commands/deleteRow";
import { deleteTable } from "../commands/deleteTable";
import { insertAbove } from "../commands/insertAbove";
import { insertBelow } from "../commands/insertBelow";
import { insertLeft } from "../commands/insertLeft";
import { insertRight } from "../commands/insertRight";
import {
    tableHasHeaderColumn,
    tableHasHeaderRow,
    toggleTableHeaderColumn,
    toggleTableHeaderRow
} from "../commands/tableHeader";
import { msgKey } from "../../RichTextEditor";
import { addSpaceAbove, addSpaceBelow } from "../commands/addSpace";

export const TableControls = ({ tablePath, doAction }) => {
    const [showOptions, setShowOptions] = useState(false);
    const editor = useSlate();

    return (
        <Row gutter={["tiny", "tiny"]}>
            <Col>
                <ButtonGroup>
                    <Button
                        square
                        icon="table-insert-row-above"
                        onMouseDown={(e) => {
                            e.preventDefault();
                            doAction(insertAbove);
                        }}
                    />
                    <Button
                        square
                        icon="table-insert-row-below"
                        onMouseDown={(e) => {
                            e.preventDefault();
                            doAction(insertBelow);
                        }}
                    />
                    <Button
                        square
                        icon="table-insert-col-left"
                        onMouseDown={(e) => {
                            e.preventDefault();
                            doAction(insertLeft);
                        }}
                    />
                    <Button
                        square
                        icon="table-insert-col-right"
                        onMouseDown={(e) => {
                            e.preventDefault();
                            doAction(insertRight);
                        }}
                    />
                </ButtonGroup>
            </Col>
            <Col>
                <PopOver
                    visible={showOptions}
                    arrow="none"
                    content={
                        <Menu>
                            <MenuItem
                                icon="table-header-row"
                                label={<FormattedMessage id={`${msgKey}.headerRow`} />}
                                selected={tableHasHeaderRow(tablePath, editor)}
                                onClick={() => {
                                    doAction(toggleTableHeaderRow);
                                    setShowOptions(false);
                                }}
                            />
                            <MenuItem
                                icon="table-header-col"
                                label={<FormattedMessage id={`${msgKey}.headerColumn`} />}
                                selected={tableHasHeaderColumn(tablePath, editor)}
                                onClick={() => {
                                    doAction(toggleTableHeaderColumn);
                                    setShowOptions(false);
                                }}
                            />
                            <MenuItem
                                icon="table-delete-row"
                                label={<FormattedMessage id={`${msgKey}.deleteRow`} />}
                                onClick={() => {
                                    doAction(deleteRow);
                                    setShowOptions(false);
                                }}
                            />
                            <MenuItem
                                icon="table-delete-col"
                                label={<FormattedMessage id={`${msgKey}.deleteColumn`} />}
                                onClick={() => {
                                    doAction(deleteColumn);
                                    setShowOptions(false);
                                }}
                            />
                            <MenuItem
                                icon="trash"
                                label={<FormattedMessage id={`${msgKey}.deleteTable`} />}
                                onClick={() => {
                                    doAction(deleteTable);
                                    setShowOptions(false);
                                }}
                            />
                            <MenuItem
                                icon="arrow-up"
                                label={<FormattedMessage id={`${msgKey}.addSpaceAbove`} />}
                                onClick={() => {
                                    doAction(addSpaceAbove);
                                    setShowOptions(false);
                                }}
                            />
                            <MenuItem
                                icon="arrow-down"
                                label={<FormattedMessage id={`${msgKey}.addSpaceBelow`} />}
                                onClick={() => {
                                    doAction(addSpaceBelow);
                                    setShowOptions(false);
                                }}
                            />
                        </Menu>
                    }
                    onClose={() => setShowOptions(false)}
                >
                    <Button active={showOptions} icon="caret-down" iconRight onClick={() => setShowOptions((s) => !s)}>
                        <FormattedMessage id={`${msgKey}.options`} />
                    </Button>
                </PopOver>
            </Col>
        </Row>
    );
};
