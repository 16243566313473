import React from "react";
import { Editor } from "slate";
import { useSlate } from "slate-react";
import { MenuItem } from "../../../../nav/Menu";

export const MarkItem = ({ format, icon, label }) => {
    const editor = useSlate();

    const isMarkActive = (editor, format) => {
        const marks = Editor.marks(editor);
        return marks ? marks[format] === true : false;
    };

    const toggleMark = (editor, format) => {
        const isActive = isMarkActive(editor, format);

        if (isActive) {
            Editor.removeMark(editor, format);
        } else {
            Editor.addMark(editor, format, true);
        }
    };

    return React.createElement(MenuItem, {
        label,
        icon,
        selected: isMarkActive(editor, format),
        onMouseDown: (event) => {
            event.preventDefault();
            toggleMark(editor, format);
        }
    });
};
