import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Transforms } from "slate";
import { useSlate } from "slate-react";
import { isValidUri } from "../../../../utils/stringUtils";
import { Alert } from "../../../alert/Alert";
import { Button } from "../../../button/Button";
import { FormItem } from "../../../form/FormItem";
import Input from "../../../form/Input";
import Popup, { PopupButtons, PopupContent, PopupTitle } from "../../../notify/Popup";
import { msgKey } from "../RichTextEditor";
import { getLinkNode, insertLink, removeLink } from "./commands";

export const LinkPrompt = ({ onClose }) => {
    const editor = useSlate();
    const [url, setUrl] = useState(null);
    const [invalidUrl, setInvalidUrl] = useState(false);

    const handleInsertLink = (event) => {
        if (!isValidUri(url)) {
            setInvalidUrl(true);
        } else {
            setInvalidUrl(false);
            insertLink(editor, url);
            onClose(event);
        }
    };

    const handleRemoveLink = (e) => {
        e.preventDefault();
        removeLink(editor);
        onClose();
    };

    useEffect(() => {
        const linkNode = getLinkNode(editor);
        if (linkNode) {
            Transforms.select(editor, linkNode[1]);
            setUrl(linkNode[0].url);
        }
    }, [editor]);

    return (
        <Popup onClose={onClose}>
            <PopupTitle>
                <FormattedMessage id={`${msgKey}.editLink`} />
            </PopupTitle>
            <PopupContent>
                <FormItem compact label={<FormattedMessage id={`${msgKey}.link`} />}>
                    <Input big value={url} onChange={(e) => setUrl(e.target.value)} placeholder="https://..." />
                </FormItem>
                {invalidUrl && (
                    <FormItem compact>
                        <Alert type="error">
                            <FormattedMessage id={`${msgKey}.invalidLink`} />
                        </Alert>
                    </FormItem>
                )}
            </PopupContent>
            <PopupButtons>
                <Button color="primary" onClick={handleInsertLink}>
                    <FormattedMessage id={`${msgKey}.saveLink`} />
                </Button>
                <Button onMouseDown={handleRemoveLink}>
                    <FormattedMessage id={`${msgKey}.removeLink`} />
                </Button>
            </PopupButtons>
        </Popup>
    );
};
