import React from "react";
import { Range } from "slate";
import { useSlate } from "slate-react";
import { Button } from "../../../../button/Button";

export const Link = ({ handleShowPrompt }) => {
    const editor = useSlate();

    return editor.selection && !Range.isCollapsed(editor.selection) ? (
        <Button
            square
            color="toolbar"
            icon="link"
            onMouseDown={(e) => {
                e.preventDefault();
                handleShowPrompt("link");
            }}
        />
    ) : null;
};
