import React from "react";
import { Button } from "../../../button/Button";
import { ButtonGroup } from "../../../button/ButtonGroup";
import { deleteImage } from "./commands";
import { useSlate } from "slate-react";

export const ImageControls = ({ element, onUpdate, onDelete }) => {
    const editor = useSlate();
    return (
        <ButtonGroup>
            <Button
                square
                icon="text-align-left"
                active={!element.align || element.align === "left"}
                onMouseDown={(e) => {
                    e.preventDefault();
                    onUpdate({ align: null });
                }}
            />
            <Button
                square
                icon="text-align-center"
                active={element.align === "center"}
                onMouseDown={(e) => {
                    e.preventDefault();
                    onUpdate({ align: "center" });
                }}
            />
            <Button
                square
                icon="trash"
                onMouseDown={(e) => {
                    e.preventDefault();
                    onDelete();
                    deleteImage(editor);
                }}
            />
        </ButtonGroup>
    );
};
