import classNames from "classnames";
import React, { useContext, useEffect, useRef } from "react";
import { AppContext } from "../../../../App";
import { Clickable } from "../../../button/Clickable";
import { TextLogo } from "../../../graphic/TextLogo";
import { ScrollableArea } from "../../ScrollableArea";
import style from "./style.scss";
import { disableBodyScroll, enableBodyScroll } from "../../../../utils/scrollUtils";

export const NavPane = ({ children }) => {
    const scrollAreaTargetRef = useRef();
    const { showSidebar, setShowSidebar, setHasSidebar } = useContext(AppContext);

    useEffect(() => {
        setHasSidebar(true);
        return () => {
            setHasSidebar(false);
        };
    }, [setHasSidebar]);

    useEffect(() => {
        const $scrollArea = scrollAreaTargetRef.current;
        if (showSidebar) {
            disableBodyScroll($scrollArea);
        } else {
            enableBodyScroll($scrollArea);
        }
        return () => {
            enableBodyScroll($scrollArea);
        };
    }, [showSidebar]);

    return (
        <div
            ref={scrollAreaTargetRef}
            className={classNames(style.base, {
                [style.isVisible]: showSidebar
            })}
        >
            {/* Content */}
            <div className={style.content}>
                {/* Logo */}
                <Clickable className={style.title} link={`/me`} onClick={() => setShowSidebar(false)}>
                    <TextLogo color="contrast" />
                </Clickable>

                {/* Menu */}
                <div className={style.wrapper}>
                    <ScrollableArea suppressScrollX>{children}</ScrollableArea>
                </div>
            </div>

            {/* Overlay */}
            <div onClick={() => setShowSidebar(false)} className={style.overlay} />
        </div>
    );
};
