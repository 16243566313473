import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Editor } from "slate";
import { useSlate, useSlateSelection } from "slate-react";
import { Button } from "../../../../button/Button";
import { Menu, MenuHeading, MenuItem } from "../../../../nav/Menu";
import { PopOver } from "../../../../nav/PopOver";
import { msgKey } from "../../RichTextEditor";
import { insertTable } from "../../table/commands/insertTable";
import { BlockItem } from "./BlockItem";

export const AddElement = ({ expanded, setExpanded, handleAddImage }) => {
    const editor = useSlate();
    const selection = useSlateSelection();
    const [insideBlock, setInsideBlock] = useState(null);

    useEffect(() => {
        const insideBlock = Editor.above(editor, {
            match: (n) => ["table"].includes(n.type)
        });
        setInsideBlock(insideBlock?.[0]?.type || null);
    }, [editor, selection]);

    return ["table"].includes(insideBlock) ? null : (
        <PopOver
            visible={expanded === "ADD_ELEMENT"}
            onClose={() => setExpanded(null)}
            positions={["bottom", "top"]}
            align="start"
            size="auto"
            arrow="none"
            content={
                <Menu>
                    <MenuHeading label={<FormattedMessage id={`${msgKey}.insertElement`} />} />
                    <MenuItem
                        icon="table"
                        label={<FormattedMessage id={`${msgKey}.table`} />}
                        onMouseDown={(event) => {
                            event.preventDefault();
                            insertTable(editor);
                            setExpanded(null);
                        }}
                    />
                    <MenuItem
                        icon="image"
                        label={<FormattedMessage id={`${msgKey}.image`} />}
                        hidden={!handleAddImage}
                        onMouseDown={(event) => {
                            event.preventDefault();
                            handleAddImage();
                            setExpanded(null);
                        }}
                    />
                    <BlockItem
                        format="blockQuote"
                        icon="quote"
                        label={<FormattedMessage id={`${msgKey}.blockQuote`} />}
                        onClick={() => setExpanded(null)}
                    />
                    <BlockItem
                        format="numberedList"
                        icon="list-ordered"
                        label={<FormattedMessage id={`${msgKey}.numberedList`} />}
                        onClick={() => setExpanded(null)}
                    />
                    <BlockItem
                        format="bulletedList"
                        icon="list"
                        label={<FormattedMessage id={`${msgKey}.bulletedList`} />}
                        onClick={() => setExpanded(null)}
                    />
                </Menu>
            }
        >
            <Button
                square
                color="toolbar"
                active={expanded === "ADD_ELEMENT"}
                icon="plus"
                onMouseDown={(e) => {
                    e.preventDefault();
                    setExpanded(expanded === "ADD_ELEMENT" ? null : "ADD_ELEMENT");
                }}
            />
        </PopOver>
    );
};
