import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Range, Text, Transforms } from "slate";
import { useSlate, useSlateSelection } from "slate-react";
import { Button } from "../../../../button/Button";
import { ColorDot } from "../../../../graphic/ColorDot";
import { Menu, MenuHeading, MenuItem } from "../../../../nav/Menu";
import { PopOver } from "../../../../nav/PopOver";
import { msgKey } from "../../RichTextEditor";
import Row from "../../../../grid/Row";
import Col from "../../../../grid/Col";

const textColors = [
    {
        hex: null,
        name: "default"
    },
    {
        hex: "#4aa1db",
        name: "blue"
    },
    {
        hex: "#36b189",
        name: "green"
    },
    {
        hex: "#ffb822",
        name: "orange"
    },
    {
        hex: "#d04b67",
        name: "red"
    }
];

const backgroundColors = [
    {
        hex: null,
        name: "none"
    },
    {
        hex: "#f6fd9a",
        name: "yellow"
    }
];

export const Colors = ({ expanded, setExpanded }) => {
    const editor = useSlate();
    const selection = useSlateSelection();
    const [hidden, setHidden] = useState(false);

    useEffect(() => {
        setHidden(!selection || (!!selection && Range.isCollapsed(selection)));
    }, [editor, selection]);

    return !hidden ? (
        <PopOver
            visible={expanded === "COLORS"}
            onClose={() => setExpanded(null)}
            positions={["bottom", "top"]}
            align="start"
            size="auto"
            arrow="none"
            content={
                <Row>
                    <Col span={24}>
                        <Menu>
                            <MenuHeading label={<FormattedMessage id={`${msgKey}.textColor`} />} />
                            {textColors.map((color) => (
                                <MenuItem
                                    key={color.hex}
                                    symbol={<ColorDot color={color.hex} />}
                                    label={<FormattedMessage id={`${msgKey}.${color.name}`} />}
                                    onMouseDown={(event) => {
                                        event.preventDefault();
                                        if (selection && !Range.isCollapsed(selection)) {
                                            Transforms.setNodes(
                                                editor,
                                                { color: color.hex },
                                                { match: (n) => Text.isText(n), split: true }
                                            );
                                        }
                                        setExpanded(null);
                                    }}
                                />
                            ))}
                        </Menu>
                    </Col>
                    <Col span={24}>
                        <Menu>
                            <MenuHeading label={<FormattedMessage id={`${msgKey}.backgroundColor`} />} />
                            {backgroundColors.map((color) => (
                                <MenuItem
                                    key={color.hex}
                                    symbol={<ColorDot color={color.hex || "white"} />}
                                    label={<FormattedMessage id={`${msgKey}.${color.name}`} />}
                                    onMouseDown={(event) => {
                                        event.preventDefault();
                                        if (selection && !Range.isCollapsed(selection)) {
                                            Transforms.setNodes(
                                                editor,
                                                { backgroundColor: color.hex },
                                                { match: (n) => Text.isText(n), split: true }
                                            );
                                        }
                                        setExpanded(null);
                                    }}
                                />
                            ))}
                        </Menu>
                    </Col>
                </Row>
            }
        >
            <Button
                square
                color="toolbar"
                active={expanded === "COLORS"}
                icon="palette"
                onMouseDown={(e) => {
                    e.preventDefault();
                    setExpanded(expanded === "COLORS" ? null : "COLORS");
                }}
            />
        </PopOver>
    ) : null;
};
