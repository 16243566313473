import React from "react";
import { FormattedMessage } from "react-intl";
import { useSlate } from "slate-react";
import { Button } from "../../../../button/Button";
import { Menu, MenuItem } from "../../../../nav/Menu";
import { PopOver } from "../../../../nav/PopOver";
import { msgKey } from "../../RichTextEditor";

export const UndoRedo = ({ expanded, setExpanded }) => {
    const editor = useSlate();
    return editor.history.undos?.length || editor.history.redos?.length ? (
        <PopOver
            visible={expanded === "UNDO_REDO"}
            onClose={() => setExpanded(false)}
            positions={["bottom", "top"]}
            align="start"
            size="auto"
            arrow="none"
            content={
                <Menu>
                    <MenuItem
                        icon="undo"
                        label={<FormattedMessage id={`${msgKey}.undo`} />}
                        hidden={!editor.history.undos?.length}
                        onMouseDown={(event) => {
                            event.preventDefault();
                            editor.undo();
                            setExpanded(null);
                        }}
                    />
                    <MenuItem
                        icon="redo"
                        label={<FormattedMessage id={`${msgKey}.redo`} />}
                        hidden={!editor.history.redos?.length}
                        onMouseDown={(event) => {
                            event.preventDefault();
                            editor.redo();
                            setExpanded(null);
                        }}
                    />
                </Menu>
            }
        >
            <Button
                square
                color="toolbar"
                active={expanded === "UNDO_REDO"}
                icon="undo"
                onMouseDown={(e) => {
                    e.preventDefault();
                    setExpanded(expanded === "UNDO_REDO" ? null : "UNDO_REDO");
                }}
            />
        </PopOver>
    ) : null;
};
