import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Menu, MenuHeading } from "../../../../nav/Menu";
import { PopOver } from "../../../../nav/PopOver";
import { msgKey } from "../../RichTextEditor";
import { BlockItem } from "./BlockItem";
import { Button } from "../../../../button/Button";
import { useSlate, useSlateSelection } from "slate-react";
import { Editor } from "slate";

export const TextAlignment = ({ expanded, setExpanded }) => {
    const editor = useSlate();
    const selection = useSlateSelection();
    const [insideBlock, setInsideBlock] = useState(null);

    useEffect(() => {
        const insideBlock = Editor.above(editor, {
            match: (n) => ["table"].includes(n.type)
        });
        setInsideBlock(insideBlock?.[0]?.type || null);
    }, [editor, selection]);

    return ["table"].includes(insideBlock) ? null : (
        <PopOver
            visible={expanded === "TEXT_ALIGNMENT"}
            onClose={() => setExpanded(false)}
            positions={["bottom", "top"]}
            align="start"
            size="auto"
            arrow="none"
            content={
                <Menu>
                    <MenuHeading label={<FormattedMessage id={`${msgKey}.textAlignment`} />} />
                    <BlockItem
                        format="left"
                        icon="text-align-left"
                        label={<FormattedMessage id={`${msgKey}.alignLeft`} />}
                    />
                    <BlockItem
                        format="center"
                        icon="text-align-center"
                        label={<FormattedMessage id={`${msgKey}.alignCenter`} />}
                    />
                    <BlockItem
                        format="right"
                        icon="text-align-right"
                        label={<FormattedMessage id={`${msgKey}.alignRight`} />}
                    />
                    <BlockItem
                        format="justify"
                        icon="text-align-justify"
                        label={<FormattedMessage id={`${msgKey}.alignJustify`} />}
                    />
                </Menu>
            }
        >
            <Button
                square
                color="toolbar"
                active={expanded === "TEXT_ALIGNMENT"}
                icon="text-align-center"
                onMouseDown={(e) => {
                    e.preventDefault();
                    setExpanded(expanded === "TEXT_ALIGNMENT" ? null : "TEXT_ALIGNMENT");
                }}
            />
        </PopOver>
    );
};
