import React from "react";
import style from "./style.scss";
// import ReCAPTCHA from "react-google-recaptcha";

export default class Captcha extends React.Component {
    render() {
        // Properties
        const { onChange } = this.props;

        return (
            <div className={style.base}>
                {/* <ReCAPTCHA
                    ref="vp"
                    type="image"
                    sitekey="6Ld_cSUUAAAAAPeePhluBUaXSEkEL-aLwX9Nq58f"
                    onChange={onChange}
                /> */}
            </div>
        );
    }
}
